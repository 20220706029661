<!-- =========================================================================================
    File Name: AgGridTable.vue
    Description: Ag Grid table
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
    Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <!-- DEPARTMENTS LIST -->
    <div id="ag-grid-demo">
      <vx-card>
        <!-- TABLE ACTION ROW -->
        <div class="flex flex-wrap justify-between items-center form-title">
          <!-- <h4 class>Test</h4> -->
          <div class="flex flex-wrap justify-end items-center">
            <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
            <div
              class="flex flex-wrap items-center justify-between ag-grid-table-actions-right"
              v-if="false"
            >
              <vs-input
                class="mb-4 md:mb-0 mr-4"
                v-model="searchQuery"
                @input="updateSearchQuery"
                placeholder="Search..."
              />
              <!-- <vs-button
                class="mb-4 md:mb-0 text-hover-white"
                @click="gridApi.exportDataAsCsv()"
              >Export as CSV</vs-button>
              </div>-->
              <vx-tooltip text="Export Excel">
                <!-- :href="excelurl+`/v2/department/excel/download?${}&search=${searchQuery}`" -->
                <a
                  :href="
                    excelurl +
                      `/v2/department/excel/download?organization_id=${organization_id}&token=${temp_token}&search=${searchQuery}`
                  "
                  target="_blank"
                  v-if="this.archive != 'archive'"
                >
                  <vs-button class="px-4">
                    <span class="d-inline-flex pr-5 text-white">Export</span>
                    <img
                      src="@/assets/images/icons/export-excel.svg"
                      class="d-inline-flex"
                      height="20"
                      width="20"
                    />
                  </vs-button>
                </a>
              </vx-tooltip>

              <vx-tooltip text="Export Archive Excel">
                <a
                  :href="
                    excelurl +
                      `/v2/department/excel/download?organization_id=${organization_id}&token=${temp_token}&archive=1&search=${searchQuery}`
                  "
                  target="_blank"
                  v-if="this.archive == 'archive'"
                >
                  <vs-button class="px-4">
                    <span class="d-inline-flex pr-5 text-white">Export</span>

                    <img
                      src="@/assets/images/icons/export-excel.svg"
                      class="d-inline-flex"
                      height="20"
                      width="20"
                    />
                  </vs-button>
                </a>
              </vx-tooltip>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6 col-sm-6 mb-3">
            <h6>Project</h6>
            <multiselect
              v-model="projects_option"
              track-by="project_id"
              label="project_name"
              @input="({ project_id }) => (this.selectedProjectId = project_id)"
              :options="projects"
              name="Project Name"
              placeholder="Select Project"
              :searchable="true"
              :allow-empty="false"
              open-direction="bottom"
              deselect-label="Can't remove this value"
            >
              <template slot="singleLabel" slot-scope="{ option }">
                {{ option.project_name }}
              </template>
            </multiselect>
            <p class="error-msg">
              <span
                class="text-danger text-sm"
                v-show="errors.has('Project Name')"
                >{{ errors.first("Project Name") }}</span
              >
            </p>
          </div>
          <div class="col-12 col-md-6 col-sm-6 mb-3">
            <h6>Vendor</h6>
            <multiselect
              :disabled="selectedProjectId ? false : true"
              v-model="vendor_options"
              track-by="vendor_id"
              label="vendor_name"
              @input="({ vendor_id }) => (this.selectedVendorId = vendor_id)"
              :options="vendors"
              name="Vendor Name"
              placeholder="Select Vendor"
              :searchable="true"
              :allow-empty="false"
              open-direction="bottom"
              deselect-label="Can't remove this value"
            >
              <template slot="singleLabel" slot-scope="{ option }">
                {{ option.vendor_name }}
              </template>
            </multiselect>
            <p class="error-msg">
              <span
                class="text-danger text-sm"
                v-show="errors.has('Vendor Name')"
                >{{ errors.first("Vendor Name") }}</span
              >
            </p>
          </div>
          <div class="col-12  mb-3 mt-3">
            <vs-button
              :disabled="!selectedProjectId && !selectedVendorId ? true : false"
              class="md:mb-0 text-hover-white ml-0 sm:ml-3 mb-2 w-full sm:w-auto"
              @click="getVARList()"
              >Search</vs-button
            >
            <vs-button
              color="warning"
              type="border"
              class="mb-2 ml-0 sm:ml-3 w-full sm:w-auto"
              @click.prevent="clearForm()"
              >Reset</vs-button
            >
            <vs-button
              class="px-4 ml-0 sm:ml-3 w-full sm:w-auto"
              @click.native="downloadExcel"
            >
              <span class="d-inline-flex pr-5 text-white">Export</span>
              <img
                src="@/assets/images/icons/export-excel.svg"
                class="d-inline-flex"
                height="20"
                width="20"
              />
            </vs-button>
          </div>
          <!-- <div class="col-6 mb-3">
              <h6>User</h6>

              <multiselect
                v-model="users_option"
                track-by="user_id"
                label="user_name"
                @input="({ user_id }) => (this.form.user_id = user_id)"
                :options="users"
                name="User Name"
                placeholder="Select User"
                :searchable="true"
                :allow-empty="false"
                open-direction="bottom"
                deselect-label="Can't remove this value"
              >
                <template slot="singleLabel" slot-scope="{ option }">
                  {{ option.user_name }}
                </template>
              </multiselect>
              <p class="error-msg">
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('User Name')"
                  >{{ errors.first("User Name") }}</span
                >
              </p>
            </div> -->
        </div>
        <ag-grid-vue
          @cell-clicked="onCellClicked"
          :gridOptions="gridOptions"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowClicked="onRowDataChanged"
          :rowData="departments"
          rowSelection="multiple"
          colResizeDefault="shift"
          :animateRows="true"
          :floatingFilter="false"
          :paginationPageSize="paginationPageSize"
          :pagination="true"
          :suppressPaginationPanel="true"
        ></ag-grid-vue>
        <vs-pagination
          :total="totalPages"
          :max="maxPageNumbers"
          v-model="currentPage"
        />
      </vx-card>
    </div>
    <div v-if="showModal == true">
      <project-vandor-advance-request-list-pop-up
        :paramsData="paramsData"
      ></project-vandor-advance-request-list-pop-up>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import * as XLSX from "xlsx";
import contacts from "../../ui-elements/ag-grid-table/data.json";
import DatetimePickerStartEnd from "@/views/components/extra-components/datetime-picker/DatetimePickerStartEnd.vue";
const VxTour = () => import("@/components/VxTour.vue");
import VendorAdvanceRequestService from "@/services/VendorAdvanceRequestService.js";
import projectVandorAdvanceRequestListPopUp from "@/views/apps/vendorAdvanceRequest/projectVandorAdvanceRequestListPopUp.vue";
import SearchService from "@/services/searchService.js";
import projectService from "@/services/projectService.js";
import ContractService from "@/services/ContractService.js";
// import departmentDetails from "./departmentDetails.vue";
import PermissionVariables from "@/services/UserManagementVaraibles.js";
import { BASEURL } from "@/config/index.js";

import "@/assets/scss/vuesax/extraComponents/agGridStyleOverride.scss";

export default {
  components: {
    AgGridVue,
    VxTour,
    projectVandorAdvanceRequestListPopUp,
    DatetimePickerStartEnd,
    "switch-component": {
      template: `<span>KOZO</span>`,
    },
  },
  props: ["listType"],
  data() {
    return {
      selectedVendorId: null,
      selectedProjectId: null,
      projects_option: [],
      vendor_options: [],
      loginUserRole: window.localStorage.getItem("UserRole"),
      permissionPageAccessArry: window.localStorage.getItem(
        "permissionPageAccess"
      )
        ? localStorage.getItem("permissionPageAccess").split(",")
        : "",
      PermissionVariables: PermissionVariables,
      vendors: [],
      projects: [],
      excelurl: BASEURL,
      showModal: false,
      paramsData: null,
      organization_id: "",
      totalPages: 0,
      overlayLoadingTemplate: null,
      archive: this.listType,
      departments: [],
      User: "Select User",
      switchValue: true,
      searchQuery: "",
      gridOptions: {},
      maxPageNumbers: 7,
      gridApi: null,
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true,
      },
      fromDate: null,
      toDate: null,
      configFromdateTimePicker: {
        minDate: new Date(),
        maxDate: null,
      },
      configTodateTimePicker: {
        minDate: null,
      },
      cityOptions: [
        { text: "New York", value: "new-york" },
        { text: "Chicago", value: "chicago" },
        { text: "San Francisco", value: "san-francisco" },
        { text: "Boston", value: "boston" },
      ],
      contacts: contacts,
      columnDefs: [],
    };
  },
  beforeMount() {
    //  this.getVARList(this.currentPage);

    this.getProjectsList();
    this.getVendorsList();

    this.organization_id = localStorage.getItem("OrganizationID");
    this.columnDefs = [
      {
        headerName: "Project Name",
        field: "project_name",
        filter: false,
        width: 255,
      },
      // {
      //   headerName: "Category",
      //   field: "category_name",
      //   filter: false,
      //   width: 255,
      // },
      {
        headerName: "Vendor Name",
        field: "po_name",
        filter: false,
        width: 200,
        suppressSizeToFit: true,
        // cellRenderer: function (params) {
        //   if (params.data.vendor_isactive == 2) {
        //     return `<span class="font-bold" style="color:red">${params.data.po_name}<div>`;
        //   } else if (params.data.vendor_isactive == 1) {
        //     return `<span class="font-bold" style="color:green">${params.data.po_name}<div>`;
        //   } else if (params.data.vendor_isactive == 5) {
        //     return `<span class="font-bold" style="color:black">${params.data.po_name}<div>`;
        //   }
        // },
      },

      {
        headerName: "Approved VAR Amount",
        field: "totalVARAmount",
        filter: false,
        cellStyle: { "text-align": "center", background: "green" },
        cellRenderer: function(params) {
          return `<span class="font-bold" style="color:white">${params.data.totalVARAmount.toFixed(
            2
          )}<div>`;
        },

        width: 255,
      },
      {
        headerName: "Approved Expense Created From VAR Amount",
        field: "totalExpenseCreatedFromVARAmount",
        filter: false,
        width: 255,
        cellStyle: { "text-align": "center", background: "green" },
        cellRenderer: function(params) {
          return `<span class="font-bold" style="color:white">${params.data.totalExpenseCreatedFromVARAmount.toFixed(
            2
          )}<div>`;
        },
      },
      {
        headerName: "Balance",
        field: "balance",
        filter: false,
        width: 255,
        cellRenderer: function(params) {
          return `<span >${
            params.data.balance ? params.data.balance.toFixed(2) : 0
          }<div>`;
        },
      },
    ];
    // this.setColums();
  },
  watch: {
    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 4;
        this.gridOptions.columnApi.setColumnPinned("email", null);
      } else this.gridOptions.columnApi.setColumnPinned("email", "left");
    },
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 100;
    },
    // totalPages() {
    //   if (this.gridApi) return this.gridApi.paginationGetTotalPages();
    //   else return 0;
    // },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        if (val) {
          // this.getVARList(val);
          return val;
        } else return 1;
      },
    },
  },
  methods: {
    downloadExcel: function() {
      let data = [];
      for (var i = 0; i < this.departments.length; i++) {
        var odata = this.departments[i];

        var obj = {};
        obj["Project Name"] = odata.project_name;
        obj["Vendor Name"] = odata.po_name;
        obj["Approved VAR Amount"] = odata.totalVARAmount;
        obj["Approved Expense Created From VAR Amount"] =
          odata.totalExpenseCreatedFromVARAmount;
        obj["Balance"] = odata.balance;

        data.push(obj);
      }
      var dataOne = XLSX.utils.json_to_sheet(data);
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, dataOne, "TRANSACTIONS"); // sheetAName is name of Worksheet
      XLSX.writeFile(wb, `VARList.xlsx`);
    },
    getProjectsList: function() {
      this.projects = [];
      projectService
        .getAllProjects(null, 1)
        .then((response) => {
          const { data } = response;
          if (data.Status == false) {
          } else {
            if (
              !this.permissionPageAccessArry.includes(
                PermissionVariables.ViewOrganizationProjects
              ) &&
              this.loginUserRole != "Admin"
            ) {
              data.data.map((x) => {
                if (
                  this.permissionPageAccessArry.includes("41P" + x.project_id)
                ) {
                  this.projects.push(x);
                }
              });
            } else {
              this.projects = data.data;
            }
            //this.projects = data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getVendorsList: function() {
      this.vendors = [];
      this.pancardList = [];
      ContractService.getContractVendorNew()
        .then((response) => {
          const { data } = response;
          if (data.Status == false) {
          } else {
            this.vendors = data.data;

            data.data.map((x) => {
              if (x.vendor_pan != null) {
                this.pancardList.push(x);
              }
            });

            data.vhc_data.map((x) => {
              if (x.is_var == "2") {
                this.VHCList.push(x);
              }
            });

            console.log(data.vhc_data);
            //this.VHCList = data.vhc_data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    onCellClicked(params) {
      console.log(params);
      this.showModal = false;
      if (params.colDef.field == "totalVARAmount") {
        params.data["modalID"] = "VAR";
        this.paramsData = params;
        setTimeout(() => {
          this.showModal = true;
        }, 100);
      }

      if (params.colDef.field == "totalExpenseCreatedFromVARAmount") {
        params.data["modalID"] = "EXP";
        this.paramsData = params;
        setTimeout(() => {
          this.showModal = true;
        }, 300);
      }
    },
    onRowDataChanged(e) {
      console.log("e :", e);
    },
    onChangeStatus: function() {
      console.log("object");
    },
    updateSearchQuery(val) {
      SearchService.getAllSerchResult(val, "department", null)
        .then((response) => {
          const { data } = response;
          if (data.data.length > 0 && data.Status) {
            this.departments = data.data;
          } else {
            this.departments = [];
            // this.$vs.notify({
            //   title: "Search data not found",
            //   text: data.Message,
            //   iconPack: "feather",
            //   icon: "check_box",
            //   color: "warning",
            // });
          }
        })
        .catch((error) => {
          this.$vs.notify({
            title: "Data Not Found",
            text: error.Message,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },
    onFromChange(selectedDates, dateStr, instance) {
      this.$set(this.configTodateTimePicker, "minDate", dateStr);
    },
    onToChange(selectedDates, dateStr, instance) {
      this.$set(this.configFromdateTimePicker, "maxDate", dateStr);
    },
    clearForm: function() {
      this.projects_option = [];

      this.vendor_options = [];
      this.selectedVendorId = null;
      this.selectedProjectId = null;
      this.departments = [];
      //  this.getVARList();
    },
    getVARList: function(currentPage) {
      this.departments = [];
      let filter = {
        page: currentPage,
        allocated: 2,
        selectedVendorId: this.selectedVendorId,
        selectedProjectId: this.selectedProjectId,
      };
      VendorAdvanceRequestService.getVARList(filter)
        .then((response) => {
          const { data } = response;
          if (data.err || data.Status == false) {
            this.$vs.notify({
              title: "Data Not Found",
              text: error.Message,
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
          } else {
            this.departments = data.data;
            this.totalPages = data.pagination.last_page;

            //if (this.departments.length <= 0) {

            //}
            // this.gridApi.sizeColumnsToFit();
          }
        })
        .catch((error) => {
          this.$vs.notify({
            title: "Data Not Found",
            text: error.Message,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },
  },
  mounted() {
    // if (this.checkPermission(["department", "GET"])) {
    // this.getCashRequestList(this.currentPage);
    // }
    this.gridApi = this.gridOptions.api;
  },
};
</script>
