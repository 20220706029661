<!-- =========================================================================================
    File Name: AgGridTable.vue
    Description: Ag Grid table
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
    Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <!-- DEPARTMENTS LIST -->
    <div id="ag-grid-demo">
      <vs-popup
        class="popmodalViewR"
        style="z-index: 999999 !important"
        :id="'popmodal'"
        title="VAR"
        :active.sync="popupActive"
      >
        <vx-card>
          <vs-button
            v-if="departments.length > 0"
            class="px-4"
            @click.native="downloadExcel"
          >
            <span class="d-inline-flex pr-5 text-white">Export</span>
            <img
              src="@/assets/images/icons/export-excel.svg"
              class="d-inline-flex"
              height="20"
              width="20"
            />
          </vs-button>
          <!-- TABLE ACTION ROW -->

          <div class="flex flex-wrap justify-between items-center form-title">
            <!-- <h4 class>Test</h4> -->
            <div class="flex flex-wrap justify-end items-center">
              <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
              <div
                class="flex flex-wrap items-center justify-between ag-grid-table-actions-right"
                v-if="false"
              >
                <vs-input
                  class="mb-4 md:mb-0 mr-4"
                  v-model="searchQuery"
                  @input="updateSearchQuery"
                  placeholder="Search..."
                />
                <!-- <vs-button
                class="mb-4 md:mb-0 text-hover-white"
                @click="gridApi.exportDataAsCsv()"
              >Export as CSV</vs-button>
              </div>-->
                <vx-tooltip text="Export Excel">
                  <!-- :href="excelurl+`/v2/department/excel/download?${}&search=${searchQuery}`" -->
                  <a
                    :href="
                      excelurl +
                      `/v2/department/excel/download?organization_id=${organization_id}&token=${temp_token}&search=${searchQuery}`
                    "
                    target="_blank"
                    v-if="this.archive != 'archive'"
                  >
                    <vs-button class="px-4">
                      <span class="d-inline-flex pr-5 text-white">Export</span>
                      <img
                        src="@/assets/images/icons/export-excel.svg"
                        class="d-inline-flex"
                        height="20"
                        width="20"
                      />
                    </vs-button>
                  </a>
                </vx-tooltip>

                <vx-tooltip text="Export Archive Excel">
                  <a
                    :href="
                      excelurl +
                      `/v2/department/excel/download?organization_id=${organization_id}&token=${temp_token}&archive=1&search=${searchQuery}`
                    "
                    target="_blank"
                    v-if="this.archive == 'archive'"
                  >
                    <vs-button class="px-4">
                      <span class="d-inline-flex pr-5 text-white">Export</span>

                      <img
                        src="@/assets/images/icons/export-excel.svg"
                        class="d-inline-flex"
                        height="20"
                        width="20"
                      />
                    </vs-button>
                  </a>
                </vx-tooltip>
              </div>
            </div>
          </div>
          <ag-grid-vue
            :gridOptions="gridOptions"
            class="ag-theme-material w-100 my-4 ag-grid-table"
            :columnDefs="columnDefs"
            :defaultColDef="defaultColDef"
            :rowClicked="onRowDataChanged"
            :rowData="departments"
            rowSelection="multiple"
            colResizeDefault="shift"
            :animateRows="true"
            :floatingFilter="false"
            :paginationPageSize="paginationPageSize"
            :pagination="true"
            :suppressPaginationPanel="true"
          ></ag-grid-vue>
          <vs-pagination
            :total="totalPages"
            :max="maxPageNumbers"
            v-model="currentPage"
          />
        </vx-card>
      </vs-popup>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import * as XLSX from "xlsx";
import contacts from "../../ui-elements/ag-grid-table/data.json";
import DatetimePickerStartEnd from "@/views/components/extra-components/datetime-picker/DatetimePickerStartEnd.vue";
const VxTour = () => import("@/components/VxTour.vue");
import VendorAdvanceRequestService from "@/services/VendorAdvanceRequestService.js";
import SearchService from "@/services/searchService.js";

// import departmentDetails from "./departmentDetails.vue";

import { BASEURL } from "@/config/index.js";

import "@/assets/scss/vuesax/extraComponents/agGridStyleOverride.scss";
import imagePopUp from "../projects/imagePopUp.vue";

export default {
  components: {
    AgGridVue,
    imagePopUp,
    VxTour,
    DatetimePickerStartEnd,
    "switch-component": {
      template: `<span>KOZO</span>`,
    },
  },
  props: {
    paramsData: Object,
  },
  data() {
    return {
      excelurl: BASEURL,
      popupActive: false,
      organization_id: "",
      totalPages: 0,
      overlayLoadingTemplate: null,
      archive: this.listType,
      departments: [],
      User: "Select User",
      switchValue: true,
      searchQuery: "",
      gridOptions: {},
      maxPageNumbers: 7,
      gridApi: null,
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true,
      },
      fromDate: null,
      toDate: null,
      configFromdateTimePicker: {
        minDate: new Date(),
        maxDate: null,
      },
      configTodateTimePicker: {
        minDate: null,
      },
      cityOptions: [
        { text: "New York", value: "new-york" },
        { text: "Chicago", value: "chicago" },
        { text: "San Francisco", value: "san-francisco" },
        { text: "Boston", value: "boston" },
      ],
      contacts: contacts,
      columnDefs: [],
    };
  },
  beforeMount() {
    this.getVARListPopUp(this.currentPage);

    this.organization_id = localStorage.getItem("OrganizationID");
    this.columnDefs = [
      {
        headerName: "VAR CODE",
        field: "VHC_code",
        filter: false,
        width: 255,
      },
      {
        headerName: "Project Name",
        field: "project_name",
        filter: false,
        width: 255,
      },

      {
        headerName: "Vendor Name",
        field: "po_name",
        filter: false,
        width: 200,
        suppressSizeToFit: true,
        cellRenderer: function (params) {
          if (params.data.vendor_isactive == 2) {
            return `<span class="font-bold" style="color:red">${params.data.po_name}<div>`;
          } else if (params.data.vendor_isactive == 1) {
            return `<span class="font-bold" style="color:green">${params.data.po_name}<div>`;
          } else if (params.data.vendor_isactive == 5) {
            return `<span class="font-bold" style="color:black">${params.data.po_name}<div>`;
          }
        },
      },

      {
        headerName: "Category",
        field: "parentCatNAme",
        filter: false,
        width: 255,
      },
      {
        headerName: "Sub-Category",
        field: "category_name",
        filter: false,
        width: 255,
      },
      // {
      //   headerName: "Sub Category",
      //   field: "sub_category_name",
      //   filter: true,
      //   width: 275,
      // },

      {
        headerName:
          this.paramsData.data.modalID == "VAR"
            ? "Approved VAR Amount"
            : " Expense Created Amount",
        field: "total_amount",
        filter: false,
        width: 255,
      },
      {
        headerName: "Invoice Number",
        field: "invoice_number",
        filter: false,
        width: 255,
      },
      {
        headerName: "Invoice Date",
        field: "invoice_date",
        filter: false,
        width: 255,
      },
      {
        headerName: "Payment Due Date",
        field: "payment_due_date",
        filter: false,
        width: 255,
      },
      {
        headerName: "Description",
        field: "purchase_description",
        filter: false,
        width: 255,
      },
      {
        headerName: "Multiple Images",
        field: "purchase_image",
        filter: true,
        width: 200,
        sortable: false,
        cellRendererFramework: imagePopUp,
        // cellRendererFramework: imagePopUp,
      },

      {
        headerName: "Status",
        field: "purchase_status",
        filter: false,
        width: 200,
        suppressSizeToFit: true,
        cellRenderer: function (params) {
          if (params.data.purchase_status == 1) {
            return `APPROVED`;
          } else if (params.data.purchase_status == 0) {
            return `PENDING`;
          } else if (params.data.purchase_status == 2) {
            return `REJECTED`;
          }
        },
      },
      // {
      //   headerName: "Expense Created From VAR Amount",
      //   field: "totalExpenseCreatedFromVARAmount",
      //   filter: false,
      //   width: 255,
      // },
      // {
      //   headerName: "Balance",
      //   field: "balance",
      //   filter: false,
      //   width: 255,
      // },
    ];
    // this.setColums();
  },
  watch: {
    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 4;
        this.gridOptions.columnApi.setColumnPinned("email", null);
      } else this.gridOptions.columnApi.setColumnPinned("email", "left");
    },
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 100;
    },
    // totalPages() {
    //   if (this.gridApi) return this.gridApi.paginationGetTotalPages();
    //   else return 0;
    // },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        if (val) {
          this.getVARListPopUp(val);
          return val;
        } else return 1;
      },
    },
  },
  methods: {
    downloadExcel: function () {
      let data = [];
      for (var i = 0; i < this.departments.length; i++) {
        var odata = this.departments[i];

        var obj = {};
        obj["VAR CODE"] = odata.VHC_code;
        obj["Project Name"] = odata.project_name;
        obj["Vendor Name"] = odata.po_name;
        obj["Category"] = odata.parentCatNAme;
        obj["Sub Category"] = odata.category_name;
        obj["Approved VAR Amount"] = odata.total_amount;
        obj["Invoice Number"] = odata.invoice_number;
        obj["Invoice Date"] = odata.invoice_date;
        obj["Payment Due Date"] = odata.payment_due_date;
        obj["Description"] = odata.purchase_description;
        obj["Status"] = odata.purchase_status;

        if (odata.purchase_status == 0) {
          obj.Status = "Pending";
        } else if (odata.purchase_status == 1) {
          obj.Status = "Approved";
        } else {
          obj.Status = "Rejected";
        }
        data.push(obj);
      }
      var dataOne = XLSX.utils.json_to_sheet(data);
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, dataOne, "TRANSACTIONS"); // sheetAName is name of Worksheet
      XLSX.writeFile(wb, `Expense.xlsx`);
    },
    onRowDataChanged(e) {
      console.log("e :", e);
    },
    onChangeStatus: function () {
      console.log("object");
    },
    updateSearchQuery(val) {
      SearchService.getAllSerchResult(val, "department", null)
        .then((response) => {
          const { data } = response;
          if (data.data.length > 0 && data.Status) {
            this.departments = data.data;
          } else {
            this.departments = [];
            // this.$vs.notify({
            //   title: "Search data not found",
            //   text: data.Message,
            //   iconPack: "feather",
            //   icon: "check_box",
            //   color: "warning",
            // });
          }
        })
        .catch((error) => {
          this.$vs.notify({
            title: "Data Not Found",
            text: error.Message,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },
    onFromChange(selectedDates, dateStr, instance) {
      this.$set(this.configTodateTimePicker, "minDate", dateStr);
    },
    onToChange(selectedDates, dateStr, instance) {
      this.$set(this.configFromdateTimePicker, "maxDate", dateStr);
    },
    getVARListPopUp: function (currentPage) {
      let filter = {
        page: currentPage,
        vendor_id: this.paramsData.data.vendor_id,
        project_id: this.paramsData.data.project_id,
        modal_id: this.paramsData.data.modalID,
      };
      VendorAdvanceRequestService.getVARListByVendor(filter)
        .then((response) => {
          const { data } = response;
          if (data.err || data.Status == false) {
          } else {
            this.departments = data.data;
            this.totalPages = data.pagination.last_page;

            // this.gridApi.sizeColumnsToFit();
          }
          this.popupActive = true;
        })
        .catch((error) => {
          this.$vs.notify({
            title: "Data Not Found",
            text: error.Message,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },
  },
  mounted() {
    // if (this.checkPermission(["department", "GET"])) {
    //this.getCashRequestList(this.currentPage);
    // }
    this.gridApi = this.gridOptions.api;
  },
};
</script>
<style>
.po-popup .vs-popup--content {
  height: 100%;
}

.popmodalViewR .vs-popup {
  width: 80% !important;
}
.outlevel {
  margin-right: 10px;
}
.inlevel {
  font-weight: bold;
}
.swiper-container {
  width: 100%;
  height: 100%;
}
.swiper-slide {
  overflow: hidden;
}
</style>
