<!-- =========================================================================================
  File Name: projects.vue
  Description: All Projects list
  ----------------------------------------------------------------------------------------
  Item Name: KOZO Admin - VueJS Dashboard 
  Author:  
  Author URL:  
========================================================================================== -->

<template>
  <div id="dashboard-analytics">
    <div class="vx-row">
      <!-- <div class="vx-col w-full">
        <router-link to>
          <feather-icon icon="ArrowLeftCircleIcon" svgClasses="h-8 w-8" />
        </router-link>
      </div> -->
      <!-- DEPARTMENT FORMS -->
      <!-- <div class="vx-col w-full mb-base">
        <department-form v-if="checkPermission(['department','POST'])"></department-form>
      </div>-->

      <div class="vx-col w-full">
        <vs-tabs>
          <!-- v-if="
              this.permissionPageAccessArry.includes(
                PermissionVariables.ViewAllocateRequestList
              ) || loginUserRole == 'Admin'
            " -->
          <vs-tab
            label="View Project Advance Request List"
            icon-pack="feather"
            icon="icon-box"
          >
            <div class="tab-text">
              <div>
                <project-vandor-advance-request-list></project-vandor-advance-request-list>
              </div>
            </div>
          </vs-tab>
          <!-- <vs-tab
            v-if="
              this.permissionPageAccessArry.includes(
                PermissionVariables.CashAllocatedUserList
              ) || loginUserRole == 'Admin'
            "
            label="Cash Allocated User List"
            icon-pack="feather"
            icon="icon-box"
          >
            <div>
              <cash-allocated-user-list></cash-allocated-user-list>
            </div>
          </vs-tab> -->
        </vs-tabs>
      </div>
    </div>
  </div>
</template>

<script>
// import cashAllocatedUserList from "@/views/apps/cashManagement/cashAllocatedUserList.vue";
import projectVandorAdvanceRequestList from "@/views/apps/vendorAdvanceRequest/projectVandorAdvanceRequestList.vue";
import PermissionVariables from "@/services/UserManagementVaraibles.js";
export default {
  data() {
    return {
      permissionPageAccessArry: window.localStorage.getItem(
        "permissionPageAccess"
      )
        ? localStorage.getItem("permissionPageAccess").split(",")
        : "",
      PermissionVariables: PermissionVariables,
      loginUserRole: window.localStorage.getItem("UserRole"),
      isActive: false,
      analyticsData: "",
      isImp: false,
      navbarSearchAndPinList: this.$store.state.navbarSearchAndPinList,
    };
  },
  methods: {},
  components: {
    // cashAllocatedUserList,
    projectVandorAdvanceRequestList,
  },
};
</script>

<style lang="scss"></style>
